<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>

<style lang="less">
@import url("common/font/font.css");
@import url("common/common.css");
* {
  margin: 0;
  padding: 0;
  /* width: 100%; */
  height: 100%;
}
.numbg1 {
  background: linear-gradient(243deg, #f2eb16 0%, #cd3531 100%) !important;
  border-radius: 14px !important;
  height: 26px !important;
  /* animation: animate-positive 2s; */
}
.numbg2 {
  background: linear-gradient(243deg, #0ad8dd 0%, #2c9ae2 100%);
  border-radius: 14px !important;
  height: 26px !important;
}
@-webkit-keyframes animate-positive {
  0% {
    width: 0%;
  }
}
@-ms-keyframes animate-positive {
  0% {
    width: 0%;
  }
}
@-moz-keyframes animate-positive {
  0% {
    width: 0%;
  }
}
@keyframes animate-positive {
  0% {
    width: 0%;
  }
}
[v-cloak] {
  display: none;
}

// .abc {
//   width: 190px;
//   padding: 0px 20px;
//   height: 52px;
//   line-height: 52px;
//   background: #082443;
//   border: 2px solid;
//   text-align: center;
//   margin-top: 22px;
//   margin-left: 46px;
//   border-image: linear-gradient(
//       270deg,
//       rgba(14, 158, 113, 1),
//       rgba(22, 241, 191, 1)
//     )
//     2 2;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   white-space: nowrap;
//   transform: skew(-25deg);
//   .num {
//     overflow: hidden;
//     text-overflow: ellipsis;
//     white-space: nowrap;
//     transform: skew(25deg);
//   }
// }
</style>
