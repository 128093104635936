import Vue from 'vue'
import App from './App.vue'
import router from './router';
Vue.config.productionTip = false

// 按需插件
import './plug/index'
// 引入动画库
import "animate.css"
// 引入echarts
import  * as  echarts from 'echarts'
Vue.prototype.$echarts = echarts
//三位一逗，保留两位小数
Vue.prototype.formatNumber = function(num){
  if(num) {
    if(!isNaN(num) && typeof num === 'number') {
      return  num.toFixed(2).toString().replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g,'$&,');
    } else {
      return  parseFloat(num).toFixed(2).toString().replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g,'$&,');
    }
  }else  {
    return  '0.00'
  }
}
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
